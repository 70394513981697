
import { Vue, Component } from "vue-property-decorator";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import { ListDataSource } from "@/data/List/ListDataSource";
import { Filter } from "@/cms-services/filter";

@Component
export default class Home extends Vue {
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.priceListId,
    config: {},
    className: "pricelist",
  });

  get caption() {
    return this.dataSource.model?.caption;
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Цены",
        to: "/manage/pricelist/",
        exact: true,
      },
      {
        text: this.dataSource.model?.caption,
        disabled: true,
      },
    ];
  }

  createItemModel: any = {
    priceListId: +this.$route.params.priceListId,
    caption: "",
    price: "",
  };

  priceListItemDataSource: any = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 100,
      filter: JSON.stringify([
        new Filter("priceListId", +this.$route.params.priceListId),
      ]),
    },
    className: "pricelistItem",
  });
}
